<template>
  <div>
    <h1>TipTab Test</h1>
    <tiptab/>
  </div>

</template>

<script>
import Tiptab from '../components/tiptab/Tiptab.vue'

export default {
  components: { Tiptab },
  

}
</script>

<style>

</style>